import { useTranslation } from 'react-i18next';
// import ReactGA from 'react-ga';
import styles from './style.module.scss';

const SocialSection = (props) => {
    const { t } = useTranslation();

    // const downloadHandler = (e) => {
    //     ReactGA.event({
    //         category: 'Button',
    //         action: 'Instagram Stickers'
    //     });
    // };

    return (
        <div className={styles.section}>
            <div className={styles.edgeMargin}>
                <div className={styles.textBlock}>
                    <h2 className={styles.header}>{t('social.title')}</h2>
                    <p className={styles.paragraph}>{t('social.paragraph')}</p>
                    {/* <button className={styles.shareBtn} onClick={downloadHandler}>
                        {t('social.ctabutton')}
                    </button> */}
                </div>
                <div className={styles.imageBlock}>
                    <img src={t('social.phone')} alt="Phone" />
                </div>
            </div>
        </div>
    );
};
export default SocialSection;
