import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import App from './components/App';

import './i18n';

TagManager.initialize({
    gtmId: 'GTM-MZPPHGL'
});

ReactGA.initialize('UA-100773913-22', {
    // TODO: turn off debug mode for GA
    debug: false
});

ReactDOM.render(
    <React.StrictMode>
        <Suspense fallback="">
            <App />
        </Suspense>
    </React.StrictMode>,
    document.getElementById('root')
);
