import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import styles from './style.module.scss';

// import IconSet from './images/iconset.png';
import Icon1 from './images/icon1.png';
import Icon2 from './images/icon2.png';
import Icon3 from './images/icon3.png';

let tl;

const IconDownloadSection = (props) => {
    const { t } = useTranslation();
    const sectionRef = useRef();

    useEffect(
        () => {
            if (!sectionRef.current) return;

            gsap.defaults({ duration: 2.0, ease: 'power3.inOut' });
            tl = gsap.timeline();
            const width = 600;
            const shift = 50;
            const slices = width / 5;
            const xLeft = slices * -1 * 1.2 + shift - 20;
            const xCenter = 0 + shift - 10;
            const xRight = slices * 1 * 0.7 + shift + 20;

            var scaleTL = gsap.timeline();
            var moveTL = gsap.timeline();

            scaleTL.add([
                gsap.to('.iconImg1', {
                    scale: 1.2,
                    transformOrigin: '100px 100px'
                }),
                gsap.to('.iconImg2', {
                    scale: 0.9,
                    transformOrigin: '100px 100px'
                }),
                gsap.to('.iconImg3', {
                    scale: 0.7,
                    transformOrigin: '100px 100px'
                })
            ]);

            moveTL.add([
                gsap.to('.icon1', {
                    x: xLeft,
                    transformOrigin: '100px 100px'
                }),
                gsap.to('.icon2', {
                    x: xCenter,
                    transformOrigin: '100px 100px'
                }),
                gsap.to('.icon3', {
                    x: xRight,
                    transformOrigin: '100px 100px'
                })
            ]);

            tl.add([ scaleTL, moveTL ]);

            ScrollTrigger.create({
                animation: tl,
                trigger: sectionRef.current,
                start: '25% center',
                end: 'center center',
                scrub: 5
                // markers: true
            });

            setTimeout(() => {
                ScrollTrigger.refresh();
            }, 100);
        },
        [ sectionRef ]
    );

    const downloadHandler = (e) => {
        ReactGA.event({
            category: 'Button',
            action: 'Download Icons'
        });

        fetch('/downloads/Ability-Signs-Icons.zip', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/zip'
            }
        })
            .then((resp) => resp.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([ blob ]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Ability-Signs-Icons.zip');

                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            });
    };
    return (
        <div ref={sectionRef} id="IconDownload" className={styles.section}>
            <div className={styles.contentRow}>
                <div className={styles.edgeMargin}>
                    <div className={styles.textBlock}>
                        <h2 className={styles.header}>{t('icondownload.title')}</h2>
                        <p className={styles.paragraph}>{t('icondownload.paragraph')}</p>
                    </div>
                    <div className={styles.imageBlock}>
                        <svg width="100%" viewBox="0 0 500 291">
                            <g className="icon3">
                                <g style={{ transform: 'translate(50%,50%)' }}>
                                    <image
                                        href={Icon3}
                                        width="200"
                                        height="200"
                                        x="-100"
                                        y="-100"
                                        className="iconImg3"
                                    />
                                </g>
                            </g>
                            <g className="icon2">
                                <g style={{ transform: 'translate(50%,50%)' }}>
                                    <image
                                        href={Icon2}
                                        width="200"
                                        height="200"
                                        x="-100"
                                        y="-100"
                                        className="iconImg2"
                                    />
                                </g>
                            </g>
                            <g className="icon1">
                                <g style={{ transform: 'translate(50%,50%)' }}>
                                    <image
                                        href={Icon1}
                                        width="200"
                                        height="200"
                                        x="-100"
                                        y="-100"
                                        className="iconImg1"
                                    />
                                </g>
                            </g>
                        </svg>
                    </div>
                </div>
            </div>

            <div className={styles.downloadBtnRow}>
                <div className={styles.edgeMargin}>
                    <button onClick={downloadHandler} className={styles.downloadBtn}>
                        {t('icondownload.ctabutton')}
                    </button>
                </div>
            </div>
        </div>
    );
};
export default IconDownloadSection;
