import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaFacebookSquare, FaYoutube, FaInstagram, FaLinkedin } from 'react-icons/fa';
import ReactPlayer from 'react-player/lazy';
import ReactGA from 'react-ga';

import styles from './style.module.scss';

// import DecathlonLogo from './images/decathlon-logo.png';

const HeaderSection = (props) => {
    const [ visStyles, setVisStyles ] = useState({ opacity: 0 });

    const { t } = useTranslation();

    const onVideoReady = (e) => {
        setVisStyles({ opacity: 1 });

        console.log('ready');
    };

    const socialLinkHandler = (target) => {
        switch (target) {
            case 'facebook':
                ReactGA.event({
                    category: 'Social Header',
                    action: 'Facebook Link'
                });
                window.open('https://www.facebook.com/decathloncanada', '_blank');
                break;
            case 'youtube':
                ReactGA.event({
                    category: 'Social Header',
                    action: 'YouTube Link'
                });
                window.open('https://www.youtube.com/channel/UCsQLcJoIBoC_quqrjROD0og', '_blank');
                break;
            case 'instagram':
                ReactGA.event({
                    category: 'Social Header',
                    action: 'Instagram Link'
                });
                window.open('https://www.instagram.com/decathloncanada/', '_blank');
                break;
            case 'linkedin':
                ReactGA.event({
                    category: 'Social Header',
                    action: 'Linkedin Link'
                });
                window.open('https://www.linkedin.com/company/d%C3%A9cathlon-canada/', '_blank');
                break;

            default:
                break;
        }
    };

    return (
        <Fragment>
            <div className={styles.ribbonSection}>
                <div className={styles.ribbonPosition}>
                    <a href={t('header.logolink')} target="_blank" rel="noreferrer">
                        <div className={styles.ribbon}>
                            <svg className={styles.svgRibbon} viewBox="0 0 100 75">
                                <polygon fill="#fff" points="0,0 100,0 100,55 0, 75" />
                            </svg>
                            <img
                                src={`/images/${t('header.logofile')}`}
                                className={styles.decathlonLogo}
                                alt="Decathlon"
                            />
                            <span className={styles.line1}>{t('header.tagline1')}</span>
                            <span className={styles.line2}>{t('header.tagline2')}</span>
                        </div>
                    </a>
                </div>
            </div>
            <div className={styles.section}>
                <div className={styles.socialBar}>
                    <div className={styles.iconRow}>
                        {/* TODO: add in social media url links */}
                        <a className={styles.socialIconLink} href="/#" onClick={(e) => socialLinkHandler('facebook')}>
                            <FaFacebookSquare />
                        </a>
                        <a className={styles.socialIconLink} href="/#" onClick={(e) => socialLinkHandler('youtube')}>
                            <FaYoutube />
                        </a>
                        <a className={styles.socialIconLink} href="/#" onClick={(e) => socialLinkHandler('instagram')}>
                            <FaInstagram />
                        </a>
                        <a className={styles.socialIconLink} href="/#" onClick={(e) => socialLinkHandler('linkedin')}>
                            <FaLinkedin />
                        </a>
                        <div className={styles.langRow}>
                            <a href="/en">en</a>
                            /
                            <a href="/fr">fr</a>
                        </div>
                    </div>
                </div>
                <div className={styles.videoSection}>
                    <ReactPlayer
                        className={styles.reactPlayer}
                        muted
                        controls={true}
                        // config={{
                        //     file: {
                        //         attributes: {
                        //             controlslist: 'nodownload noremoteplayback'
                        //         },
                        //         disablepictureinpicture: true
                        //     }
                        // }}
                        loop
                        playing
                        onStart={() => {
                            onVideoReady();
                        }}
                        style={visStyles}
                        width="100%"
                        height="100%"
                        playsinline
                        url={[ t('header.videoMP4'), t('header.videoWEBM') ]}
                    />
                </div>
                <div className={styles.headlineSection}>
                    {/* <div className={styles.edgeMargin}> */}
                    <h3 className={styles.headline}>{t('header.headline')}</h3>
                    {/* </div> */}
                </div>
            </div>
        </Fragment>
    );
};
export default HeaderSection;
