import { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { usePath } from 'hookrouter';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import Header from '../sections/Header';
import IconDownload from '../sections/IconDownload';
import Share from '../sections/Share';
import Social from '../sections/Social';
import Footer from '../sections/Footer';

import 'reset-css';
import '../../scss/Globals.module.scss';
import styles from './style.module.scss';

gsap.registerPlugin(ScrollTrigger);

function App() {
    const { t } = useTranslation();
    const path = usePath();

    useEffect(
        () => {
            if (path === '/fr') i18next.changeLanguage('fr');
            else if (path === '/en') i18next.changeLanguage('en');
            else i18next.changeLanguage('en');
        },
        [ path ]
    );

    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{t('meta.title')}</title>
                <meta name="description" content={t('meta.description')} />
            </Helmet>

            <div className={styles.wrapper}>
                <Header />
                <IconDownload />
                <Share />
                <Social />
                <Footer />
            </div>
        </Fragment>
    );
}

export default App;
