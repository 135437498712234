import { useTranslation, Trans } from 'react-i18next';
import { FaFacebookSquare, FaYoutube, FaInstagram, FaLinkedin } from 'react-icons/fa';
import ReactGA from 'react-ga';
import styles from './style.module.scss';

// import DecathlonLogo from './images/decathlon-logo.png';

export const LinkText = (props) => {
    return (
        <a {...props} href={props.href || ''}>
            decathlon.ca
        </a>
    );
};

const Footer = (props) => {
    const { t } = useTranslation();
    const socialLinkHandler = (target) => {
        switch (target) {
            case 'facebook':
                ReactGA.event({
                    category: 'Social Footer',
                    action: 'Facebook Link'
                });
                window.open('https://www.facebook.com/decathloncanada', '_blank');
                break;
            case 'youtube':
                ReactGA.event({
                    category: 'Social Footer',
                    action: 'YouTube Link'
                });
                window.open('https://www.youtube.com/channel/UCsQLcJoIBoC_quqrjROD0og', '_blank');
                break;
            case 'instagram':
                ReactGA.event({
                    category: 'Social Footer',
                    action: 'Instagram Link'
                });
                window.open('https://www.instagram.com/decathloncanada/', '_blank');
                break;
            case 'linkedin':
                ReactGA.event({
                    category: 'Social Footer',
                    action: 'Linkedin Link'
                });
                window.open('https://www.linkedin.com/company/d%C3%A9cathlon-canada/', '_blank');
                break;

            default:
                break;
        }
    };
    return (
        <div className={styles.section}>
            <div className={styles.edgeMargin}>
                <p className={styles.note}>
                    <Trans
                        t={t}
                        i18nKey="footer.note"
                        components={{ link: <LinkText href="https://decathlon.ca" target="_blank" /> }}
                    />
                </p>
                <a href={t('footer.logolink')} target="_blank" rel="noreferrer">
                    <img className={styles.decathlonLogo} src={`/images/${t('footer.logofile')}`} alt="Decathlon" />
                </a>
                <div className={styles.iconRow}>
                    {/* TODO: add in social media url links */}
                    <a className={styles.socialIconLink} href="/#" onClick={(e) => socialLinkHandler('facebook')}>
                        <FaFacebookSquare />
                    </a>
                    <a className={styles.socialIconLink} href="/#" onClick={(e) => socialLinkHandler('youtube')}>
                        <FaYoutube />
                    </a>
                    <a className={styles.socialIconLink} href="/#" onClick={(e) => socialLinkHandler('instagram')}>
                        <FaInstagram />
                    </a>
                    <a className={styles.socialIconLink} href="/#" onClick={(e) => socialLinkHandler('linkedin')}>
                        <FaLinkedin />
                    </a>
                </div>
                <p className={styles.legal}>{t('footer.legal')}</p>
            </div>
        </div>
    );
};
export default Footer;
