import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import gsap from 'gsap';
import styles from './style.module.scss';

import Mosaique from './images/mosaique.png';
import AbilityHashtag from './images/ability-hashtag.png';

const ShareSection = (props) => {
    const { t } = useTranslation();
    const loopRef = useRef();
    useEffect(
        () => {
            if (loopRef.current)
                gsap
                    .timeline()
                    .fromTo(loopRef.current, { x: '0%' }, { x: '-100%', duration: 20, ease: 'none', repeat: -1 });
        },
        [ loopRef ]
    );
    return (
        <div className={styles.section}>
            <div className={styles.edgeMargin}>
                <div className={styles.textBlock}>
                    <h2 className={styles.header}>{t('share.title')}</h2>
                    <p className={styles.paragraph}>{t('share.paragraph')}</p>
                </div>
                <div className={styles.imageBlock}>
                    <img src={Mosaique} alt="Mosaique" />
                </div>
            </div>
            {/* BUG: firefox height issue */}
            <div className={styles.abilityHashtagLoop} ref={loopRef}>
                <img className={styles.abilityHashtag} src={AbilityHashtag} alt="#AbilitySigns" />
                <img className={styles.abilityHashtag} src={AbilityHashtag} alt="#AbilitySigns" />
            </div>
        </div>
    );
};
export default ShareSection;
